import { Fragment } from 'react';
import wurd, { WurdText } from 'wurd-react';

import store from '../../store';
import * as actions from '../../actions';
import * as helpers from '../../helpers';
import { useItemLoader } from 'hooks';

import Section from '../section';
import UnitDetailsForm from './forms/unit-details';
import UnitRentalForm from './forms/unit-rental';
import SystemCharges from './system-charges';
import CustomFieldsSection from 'plugins/custom-fields/section';
import DetailList from 'components/detail-list';
import Link from 'components/nested-link';
import LangText from 'components/lang-text';
import { Tag } from 'components/unit-type-detail/features';


const wurdKey = 'unitView';
const cms = wurd.block(wurdKey);

const { currency } = helpers.ui;
const { hasRole } = helpers.auth;

const Label = ({ id }) => (
  <WurdText type="dt" id={`unit.${id}.label`} />
);


export default function UnitDetails({
  site,
  unit,
  rental,
  modal,
  fetchUnit,
}) {
  const { settings } = store.get();
  const featureConfigs = helpers.unit.tag.byId();

  const { item: unitType } = useItemLoader('unit-types', unit?.typeId, {
    include: ['customFields'],
  });

  const showInvoiceDate = settings.invoiceGenerationMode === 'anniversary';

  function onChange() {
    fetchUnit();
    modal.close();
  }

  function editUnitRental() {
    modal.open(
      <UnitRentalForm
        initialValue={rental}
        onSubmit={(data) => actions.unitRentals.update(rental.id, data, { include: 'customFields' })}
        onSuccess={onChange}
        closeModal={modal.close}
        mode="update"
        accessTypes={{ ...Object.fromEntries(Object.keys(rental.accessCodes || {}).map(k => [k, 'read,write'])), ...site.accessTypes }}
      />,
    );
  }

  // function editUnitPricing() {
  //   modal.open(
  //     <UnitPricingForm
  //       initialValue={unit}
  //       onSubmit={
  //         (data, patches) => actions.units.update(unit.id, data)
  //           .then(async () => { // update site stats if typeId is changed
  //             if (patches.typeId) {
  //               await actions.update('sites', unit.siteId, { updateAvailability: true })
  //             }
  //           })
  //       }
  //       onSuccess={modal.close}
  //       closeModal={modal.close}
  //       mode="update"
  //     />,
  //   );
  // }

  function editUnitDetails() {
    modal.open(
      <UnitDetailsForm
        initialValue={unit}
        onSubmit={(data) => actions.units.update(unit.id, data)}
        onSuccess={modal.close}
        closeModal={modal.close}
        mode="update"
      />,
    );
  }

  return (
    <>
      {rental && (
        <>
          <Section
            cms={cms.block('rentalDetails')}
            onClickEdit={editUnitRental}
          >
            <DetailList>
              <Label id="price" />
              <dd>
                {currency(rental.price)}{' '}
                {unit && rental.price !== unit.defaultPrice && (
                  <WurdText
                    className="text-muted fw-light"
                    type="del"
                    id="unitView.defaultPrice"
                    vars={{ defaultPrice: currency(unit.defaultPrice) }}
                  />
                  )}
                {' '}
                <WurdText
                  id={`common.phrases.rentPer_${settings.invoicePeriod}`}
                  className="text-muted"
                />
              </dd>

              {rental.priceUpdated && (
                <>
                  <Label id="priceUpdated" />
                  <dd>
                    {helpers.ui.date(rental.priceUpdated)}
                  </dd>
                </>
              )}
            </DetailList>

            <DetailList>
              <Label id="deposit" />
              <dd>
                {currency(rental.deposit)}{' '}
                {unit && rental.deposit !== unit.defaultDeposit && (
                  <WurdText
                    className="text-muted"
                    style={{ textDecoration: 'line-through' }}
                    id="unitView.defaultDeposit"
                    vars={{ defaultDeposit: currency(unit.defaultDeposit) }}
                  />
                )}
              </dd>

              {showInvoiceDate && (
                <>
                  <Label id="invoiceDate" />
                  <dd>{rental.invoiceDate || '-'}</dd>
                </>
              )}

              {rental.agreementUrl && (
                <>
                  <Label id="agreementUrl" />
                  <dd><a href={rental.agreementUrl} className="btn-link single-line" style={{ display: 'inline-block', maxWidth: '100%' }} target="_blank">{rental.agreementUrl}</a></dd>
                </>
              )}

              {Object.entries(rental.accessCodes || {}).map(([key, value]) => {
                return (
                  <Fragment key={key}>
                    <dt><i className="fas fa-shield-alt" style={{ fontSize: '80%', marginRight: 2 }} /><WurdText id={`unit.${key}.label`} /></dt>
                    <dd>{value || <cms.Text id="rental.accessCode.loading" />}</dd>
                  </Fragment>
                );
              })}
            </DetailList>
          </Section>

          <CustomFieldsSection
            cms={cms.block('rentalCustomFields')}
            modelName="unitRental"
            model={rental}
            modal={modal}
            onChange={onChange}
          />
        </>
      )}

      {unit && (
        <>
          <Section
            cms={cms.block('details')}
            onClickEdit={hasRole('manager') && editUnitDetails}
          >
            <DetailList>
              <Label id="typeId" />
              <dd><Link to={`/sites/${site?.code}/unit-types/${unitType?.id}`}>{helpers.ui.getLangText(unitType?.title)}</Link></dd>

              <Label id="name" />
              <dd className="text-uppercase">{unit.name}</dd>

              <Label id="size" />
              <dd>{helpers.unit.size(unit, site?.measure, true)}</dd>

              <Label id="area" />
              <dd>{helpers.unit.area(unit, site?.measure)}</dd>

              <Label id="volume" />
              <dd>{helpers.unit.volume(unit, site?.measure)}</dd>

              <Label id="floor" />
              <dd>{unit.floor}</dd>

              {unit.defaultPrice != null && (
                <>
                  <Label id="defaultPrice" />
                  <dd>{currency(unit.defaultPrice)}</dd>
                </>
              )}

              {unit.defaultDeposit != null && (
                <>
                  <Label id="defaultDeposit" />
                  <dd>{currency(unit.defaultDeposit)}</dd>
                </>
              )}

              {Object.keys(featureConfigs).length > 0 && (
                <>
                  <Label id="tags" />
                  <dd style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    {unit.featureIds.map(featureId => {
                      const featureConfig = featureConfigs[featureId];

                      if (!featureConfig) {
                        console.warn(`Unknown feature: ${featureId}`);
                        return null;
                      }

                      return (
                        <Tag key={featureId} className="label label-info">
                          <i className={featureConfig.icon} style={{ marginRight: 6 }} /><LangText obj={featureConfig.title} />
                        </Tag>
                      );
                    })}
                  </dd>
                </>
              )}
            </DetailList>
          </Section>
        </>
      )}

      <CustomFieldsSection
        cms={cms.block('customFields')}
        modelName="unit"
        model={unit}
        modal={modal}
        onChange={onChange}
      />
      
      {rental && (
        <SystemCharges
          modal={modal}
          cms={cms.block('systemCharges')}
          unitRental={rental}
          onChange={onChange}
        />
      )}
    </>
  );
}
