import wurd from 'wurd-react';

import * as actions from '../../../../actions';

import Alert from '../../../alert';
import JobStep from '../../job-step2';
import ApiButton from '../../../api-button';
import { Section, Heading } from '../../components';
import OrderSummary from '../summary';
import ConfirmAction from '../../confirm-action';
import OperationsBar from '../operations-bar';

const cms = wurd.block('unitOrder.steps.await_submitOrder');


export default function UnitBookJobSteps_SubmitOrder({
  site,
  job,
  step,
  owner,
  unit,
  rental,
  onChange,
  modal,
}) {
  async function completeStep() {
    await actions.jobs.updateStep(job.id, step.id, {
      state: 'completed',
    });

    onChange();
  }

  function render_ready() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="ready.title" />}
      >
        <Section>
          <Alert type="info">
            <cms.Markdown id="info" />
          </Alert>

          <Heading><cms.Text id="summary" /></Heading>
  
          <OrderSummary
            job={job}
            owner={owner}
            unit={unit}
            rental={rental}
            modal={modal}
            onChange={onChange}
          />

          <OperationsBar
            job={job}
            modal={modal}
            onChange={onChange}
            buttons={['previewInvoice', 'viewAgreement', 'cancelJob']}
          />
        </Section>
  
        <div className="btn-group btn-group-justified">
          <div className="btn-group">
            <ConfirmAction
              mustConfirm
              /* alertBtn={{
                //onClick: () => actions.invoices.process(invoice.id).finally(onChange),
                //text: <cms.Text id="chargeInvoice" />
                text: 'Foo',
              }} */
              cms={cms.block('confirmCompletion')}
            >
              <ApiButton
                bsType="success"
                onClick={completeStep}
              >
                <i className="fas fa-check" />
                &nbsp;
                <cms.Text id="requiresInput.completeBtn" />
              </ApiButton>
            </ConfirmAction>
          </div>
        </div>
      </JobStep>
    );
  }

  function render_completed() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" />}
      />
    );
  }

  if (step.state === 'ready') return render_ready();
  if (step.state === 'completed') return render_completed();

  return null;
}
