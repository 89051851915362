import wurd from 'wurd-react';

import { useListLoader } from 'hooks';

import LangText from 'components/lang-text';
import AddonConfigLoader from 'components/loaders/addon-config';

import aliases from './blocks';

export default function BlockSelectorModal({
  cms,
  modal,
  settings,
  page,
  mode = 'update',
  type,
  onChange,
  block_prefix,
}) {
  const { items: addons } = useListLoader('addons', { state: 'enabled' }, { maxAge: 0 });

  return (
    <>
      <div className="modal-header">
        <button type="button" className="close" onClick={modal.close}>×</button>
        <cms.Text id={`editType.title_${mode}`} type="h4" />
      </div>
      <div className="modal-body">
        <div style={{ columns: 2, gap: '2rem' }}>
          {Object.entries(aliases).map(([blockName, alias]) => {
            if (block_prefix && !blockName.startsWith(block_prefix)) return null;
            if (!block_prefix && blockName.startsWith('header') && page.header) return null;
            if (!block_prefix && blockName.startsWith('footer') && page.footer) return null;
            if (blockName.startsWith('sites') && !settings.modes?.includes('selfStorage')) return null;

            return (
              <div
                key={blockName}
                style={{ breakInside: 'avoid-column', marginBottom: '1.5rem' }}
              >
                <strong style={{ padding: 2 }}>{blockName}</strong>
                <fieldset
                  style={{
                    border: '1px dashed #dadada',
                    cursor: 'pointer', 
                    outline: 'blockType' === type ? '1px auto var(--bs-primary)' : null
                  }}
                  onClick={() => {
                    onChange(blockName, cms.text(`editType.${blockName}.defaultContent`));
                    if (!wurd.editMode) modal.close();
                  }}
                >
                  <cms.Image id={`editType.${blockName}.preview`} style={{ width: '100%' }} />
                  {wurd.editMode && (
                    <cms.Text
                      id={`editType.${blockName}.defaultContent`}
                      type="pre"
                      style={{ whiteSpace: 'pre-wrap', resize: 'both' }}
                    />
                  )}
                </fieldset>
              </div>
            );
          })}

          {addons.length > 0 && (
            <>
              <hr />
              <h4>Add-ons</h4>

              {addons.map((addon) => (
                <div
                  key={addon.id}
                  onClick={() => {
                    onChange('addon', JSON.stringify({ addonId: addon.id }));
                    if (!wurd.editMode) modal.close();
                  }}
                >
                  <AddonConfigLoader id={addon.addonConfigId}>
                    {addonConfig => {
                      if (!addonConfig.websiteBlock?.en) return null;

                      return (
                        <div
                          style={{
                            border: '1px dashed #dadada',
                            cursor: 'pointer',
                            textAlign: 'center',
                            padding: '1em',
                            marginTop: '1em',
                          }}>
                          <div><img src={addonConfig.image} style={{ height: 50 }} /></div>
                          <LangText obj={addonConfig.title}></LangText>
                        </div>
                      );
                    }}
                  </AddonConfigLoader>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}