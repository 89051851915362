import wurd from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';
import { hasRole } from 'helpers/auth';

import Section from 'components/section';
import { Field } from 'components/form2';
import RadioList from 'components/form2/radiolist';
import Alert from 'components/alert';
import SettingsForm2 from '../form2';
import Input from '../input';

const inputsCms = wurd.block('settings.inputs');
const cms = wurd.block('settings.invoiceGeneration');


function DateOfMonthExample({ settings }) {
  const { nextInvoiceCreateDate, nextInvoiceChargeDate, nextInvoiceStartDate, nextInvoiceEndDate } = helpers.invoice.calculateDates_dateOfMonth(settings);

  return (
    <Alert type="info">
      <cms.Markdown
        id="dateOfMonth.example"
        vars={{
          invoiceCreateDate: helpers.ui.date(nextInvoiceCreateDate),
          invoiceChargeDate: helpers.ui.date(nextInvoiceChargeDate),
          invoiceStartDate: helpers.ui.date(nextInvoiceStartDate),
          invoiceEndDate: helpers.ui.date(nextInvoiceEndDate),
          invoiceDateRange: helpers.ui.datePeriod(nextInvoiceStartDate, nextInvoiceEndDate)
            .map(v => `**${v}**`)
            .join(' - '),
        }}
      />
    </Alert>
  );
}

function AnniversaryExample({ settings }) {
  const { exampleDate, nextInvoiceAnniversaryCreateDate, nextInvoiceAnniversaryChargeDate } = helpers.invoice.calculateDates_anniversary(settings);
  return (
    <Alert type="info">
      <cms.Markdown id="anniversary.example" vars={{
        invoiceStartDate: exampleDate,
        invoiceCreateDate: helpers.ui.date(nextInvoiceAnniversaryCreateDate),
        invoiceChargeDate: helpers.ui.date(nextInvoiceAnniversaryChargeDate),
      }} />
    </Alert>
  );
}


export default function InvoiceGenerationSettings({ settings }) {
  return (
    <Section cms={cms}>
      <SettingsForm2
        wurdSection="settings.inputs"
        initialValue={settings}
        onSubmit={(data, dataPatches) => actions.business.update(dataPatches)}
      >
        {({ fieldProps, inputProps, formValue }) => {
          return (
            <>
              {settings.modes.includes('selfStorage') && (
                <>
                  <Field {...fieldProps('invoiceGenerationMode')} className={null} disabled>
                    <RadioList
                      disabled={!hasRole('_support')}
                    >
                      {['dateOfMonth', 'anniversary'].map(v => ({
                        value: v,
                        title: <inputsCms.Text id={`invoiceGenerationMode.${v}.title`} />,
                        description: <inputsCms.Text id={`invoiceGenerationMode.${v}.desc`} type="p" className="text-muted" />
                      }))}
                    </RadioList>
                  </Field>

                  {formValue.invoiceGenerationMode === 'anniversary' && (
                    <>
                      <Field {...fieldProps('invoicePeriod')}>
                        <select
                          disabled={!hasRole('_support')}
                        >
                          {['day', 'week', '4weeks', 'month'].map(v => (
                            <option key={v} value={v}>
                              {v}
                              {inputsCms.text(`invoicePeriod.${v}.title`)}
                            </option>
                          ))}
                        </select>
                      </Field>

                      <Input
                        {...fieldProps('invoiceGeneration_anniversary_createDaysAhead')}
                        type="number"
                        min="0"
                        max="30"
                        disabled={!hasRole('_support')}
                      />

                      {formValue.invoiceGeneration_anniversary_createDaysAhead > 0 && (
                        <Input
                          {...fieldProps('invoiceGeneration_anniversary_chargeDaysAhead')}
                          type="number"
                          min="0"
                          max="30"
                          disabled={!hasRole('_support')}
                        />
                      )}

                      <AnniversaryExample settings={formValue} />
                    </>
                  )}

                  {formValue.invoiceGenerationMode === 'dateOfMonth' && (
                    <>

                      <Field {...fieldProps('invoiceGeneration_dateOfMonth_createDate')} >
                        <input type="number" min="1" max="28" disabled={!hasRole('_support')}   />
                      </Field>

                      <Field {...fieldProps('invoiceGeneration_dateOfMonth_chargeDate')} >
                        <input type="number" min="1" max="28" disabled={!hasRole('_support')} />
                      </Field>

                      <Field {...fieldProps('invoiceGeneration_dateOfMonth_startDate')} >
                        <input type="number" min="1" max="28" disabled={!hasRole('_support')}  />
                      </Field>

                      <Field {...fieldProps('invoiceGeneration_dateOfMonth_includePeriod1Date')} >
                        <input type="number" min="1" max="28" disabled={!hasRole('_support')}  />
                      </Field>

                      <DateOfMonthExample settings={formValue} />
                    </>
                  )}
                </>
              )}
            </>
          );
        }}
      </SettingsForm2>
    </Section>
  );
}
