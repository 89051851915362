import wurd, { WurdText } from 'wurd-react';
import { useQuery } from 'react-query';

import * as actions from '../../actions';
import * as helpers from '../../helpers';
import api from '../../utils/api';

import ApiButton from '../api-button';
import SidebarState from '../sidebar-items/state';
import SidebarUser from '../sidebar-items/user';
import SidebarUnit from '../sidebar-items/unit';
import SidebarDate from '../sidebar-items/date';

const cms = wurd.block('invoiceView.state');

const { date, confirm } = helpers.ui;
const { stateBsTypes } = helpers.invoice;


export default function InvoiceDetailSidebarSummary({
  invoice,
  unitRental = null,
  onChange,
}) {
  const { data: cards } = useQuery(`billing-cards-${invoice.owner.id}`, () => api.get(`/v1/admin/users/${invoice.owner.id}/billing/sources`).then(res => res.data), { enabled: invoice.billing?.type === 'custom-billing' });

  function sendInvoice() {
    return actions.invoices.send(invoice.id);
  }

  function chargeCard() {
    return actions.invoices.process(invoice.id).finally(onChange);
  }

  function processRefund() {
    const refundAmount = helpers.ui.currency(-invoice.balance);
    const confirmed = confirm(cms.text('confirmRefund', { refundAmount }));

    if (!confirmed) return;

    return actions.invoices.process(invoice.id).finally(onChange);
  }

  function markPaid() {
    return actions.invoices.update(invoice.id, { state: 'paid' });
  }

  const SendButton = ({ buttonWurdKey = 'send' }) => (
    <cms.Object keys="send,sending,resend,resendReceipt">
      <ApiButton
        block
        onClick={sendInvoice}
      >
        <i className="fas fa-paper-plane" /> {cms.text(buttonWurdKey)}
      </ApiButton>
    </cms.Object>
  );

  const ChargeButton = () => {
    return (
      <cms.Object keys="charge,charging,retryCharge">
        <ApiButton
          block
          onClick={chargeCard}
          loadingMsg={cms.text('charging')}
          disabled={invoice.billing?.type === 'custom-billing' && !cards?.length}
        >
          <i className="fas fa-credit-card" /> {cms.text('charge')}
        </ApiButton>
      </cms.Object>
    );
  };

  const RefundButton = () => {
    return (
      <cms.Object keys="refund,confirmRefund,refunding">
        <ApiButton
          block
          onClick={processRefund}
          bsType="warning"
          loadingMsg={cms.text('refunding')}
          disabled={invoice.billing?.type === 'custom-billing' && !cards?.length}
        >
          <i className="fas fa-credit-card" /> {cms.text('refund')}
        </ApiButton>
      </cms.Object>
    );
  };

  const ChargeOrRefundButton = () => {
    if (!invoice.billing) return null;
    if (invoice.balance === 0) return null;
    const isRefund = invoice.balance < 0;
    // if (isRefund && ['gmopg', 'cybersource'].includes(invoice.billing?.type)) return null; // not supported

    return isRefund ? <RefundButton /> : <ChargeButton />;
  };

  const MarkPaidButton = () => {
    if (invoice.balance !== 0) return null;

    return (
      <cms.Object keys="markPaid">
        <ApiButton
          bsType="success"
          block
          onClick={markPaid}
        >
          <i className="fas fa-check" /> &nbsp;{cms.text('markPaid')}
        </ApiButton>
      </cms.Object>
    );
  };


  return (
    <div className="list-group">
      <SidebarState type={stateBsTypes[invoice.state]}>
        <div className={`pull-right ${invoice.overdue ? 'text-danger' : ''}`} style={{ fontWeight: 'normal' }}>
          {invoice.state === 'sent' && invoice.sent && date(invoice.sent)}
          {invoice.state === 'paid' && invoice.paid && date(invoice.paid)}
          {invoice.state === 'failed' && invoice.failed && date(invoice.failed)}
          {invoice.overdue && <cms.Object keys="overdue"><i className="far fa-exclamation-circle" title={cms.text('overdue')} style={{ marginLeft: 4, fontSize: '1.2em' }} /></cms.Object>}
        </div>
        <WurdText id={`invoice._states.${invoice.state}`} />
      </SidebarState>

      <SidebarUser id={invoice.owner.id} />

      {invoice.unitRentalId && (
        <SidebarUnit id={unitRental?.unitId} path={`/unit-rentals/${invoice.unitRentalId}/invoices`} />
      )}

      <SidebarDate
        title={<cms.Text id="date" />}
        date={invoice.startDate}
        endDate={invoice.endDate}
      />

      {['draft', 'failed'].includes(invoice.state) && !invoice.deleted && (
        <div className="list-group-item">
          <SendButton />
          <ChargeOrRefundButton />
          <MarkPaidButton />
        </div>
      )}

      {invoice.state === 'sent' && !invoice.deleted && (
        <div className="list-group-item">
          <SendButton buttonWurdKey="resend" />
          <ChargeOrRefundButton />
          <MarkPaidButton />
        </div>
      )}
    </div>
  );
}
