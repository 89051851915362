import * as helpers from '../../../../helpers';

const styles = {
  wrapper: {
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 16,
    cursor: 'pointer',
  }
}

export default function SitemapSidePanelUnit({ unit, onClick }) {
    
  return (
    <li style={styles.wrapper} onClick={onClick}>
      <span className="fw-normal">{unit.name}</span>
      <small className="text-muted" style={{ marginLeft: 12 }}>{helpers.unit.size(unit)}</small>
    </li>
  );

}
