import { WurdText, WurdMarkdown } from 'wurd-react';
import styled from '@emotion/styled';

import store from 'store';
import StateLabel from 'components/state-label';
import { Checklist } from '../../form2';


const Help = styled.div({
  lineHeight: '16px',
  color: '#777b',
  p: {
    display: 'contents',
    lineHeight: '16px',
    fontSize: '10px',
  }
});

const allEvents = {
  'business.settings.updated': {},
  'business.updated': { deprecated: true, removed: true },
  'site.settings.updated': {},
  'site.availability.updated': {},
  'unitType.updated': { deprecated: true, removed: true },
  'unitType.settings.updated': {},
  'unitType.availability.updated': {},

  'addon.installed': {},
  'addon.updated': {},
  'addon.customFields.validate': { deprecated: true },
  'addon.dailyEvent.started': {},
  'job.addon_update.submitted': { deprecated: true },
  
  'invoice.state.updated': { beta: true },
  'invoice.payments.updated': { beta: true },
  'invoice.deleted': {},
  'invoice.created': { deprecated: true },

  'items.updated': {},
  
  'job.unit_moveIn.create_started': {},
  'job.unit_moveIn.created': {},
  'job.unit_moveIn.started': {},
  'job.unit_moveIn.completed': {},
  'job.unit_moveIn.cancelled': {},
  
  'job.unit_moveOut.created': {},
  'job.unit_moveOut.completed': {},
  'job.unit_moveOut.cancelled': {},

  'job.unit_transfer.completed': {},
  
  'job.valetOrder.submitted': {},
  'job.valetOrder.created': {},
  'job.valetOrder.started': {},
  'job.valetOrder.completed': {},
  'job.valetOrder.cancelled': {},
  
  'unit.reserved': {},
  'unit.occupied': {},
  'unit.blocked': {},
  'unit.unassigned': {},
  'unit.unblocked': {},
  'unit.archived': {},

  // sent for security systems
  'unitRental.markOverdue': {},
  'unitRental.unmarkOverdue': {},
  'unitRental.updated': {},
  'unitRental.charges.updated': {},
  'unitRental.invoice.created': {}, // for unitRental/self storage invoices
  
  'user.created': {},
  'user.updated': {},
  'user.billing.updated': {},
  'user.disabled': {},

  // Valet specific
  'valet.invoice.created': {}, // for user/valet invoices
};

const customBillingEvents = [
  'billing.charge',
  'billing.checkout',
  'billing.list',
];


export default function DeveloperEventsChecklist(props) {
  const { value = [] } = props;
  const { settings } = store.get();
  
  // Filter out deprecated events that are not already checked
  const events = Object.entries(allEvents)
    .map(([event, options]) => ({ id: event, ...options }))
    .filter(event => !event.deprecated || value.includes(event.id));

  // Custom billing events can be added in some situations
  if (settings.billing === 'custom-billing') {
    events.push(...customBillingEvents.map(id => ({ id, beta: true })));
  }

  return (
    <Checklist {...props}>
      {events.map(event => (
        <div key={event.id} id={event.id} disabled={event.deprecated}>
          <div>
            {event.id}&nbsp;
            {event.beta && (<StateLabel type="warning"><WurdText id="developer.beta" /></StateLabel>)}
            {event.deprecated && (<StateLabel type="danger"><WurdText id="developer.deprecated" /></StateLabel>)}
          </div>
          <WurdMarkdown id={`developer.events.${event.id}.help`} type={Help} />
        </div>
      ))}
    </Checklist>
  );
}
