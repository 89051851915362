import styled from '@emotion/styled';
import { Doughnut } from 'react-chartjs-2';


const Wrapper = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',

  '.chart': {
    flex: 1,
    position: 'relative',
    height: 200,
    width: 200,
    marginRight: 40,

    '.summary': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -24,
      width: 75,
      marginLeft: -38,
      textAlign: 'center',
      fontSize: 24,
  
      '.title': {
        fontSize: 12,
        color: '#999'
      }
    },
  },

  '.legendTable': {
    whiteSpace: 'nowrap',
    zIndex: 1,
    backgroundColor: '#fffa',
  },
});


const chartOptions = { // https://www.chartjs.org/docs/latest/charts/doughnut.html#dataset-properties
  offset: 3,
};


export default function SitePieChart({
  data,
  summaryPercent,
  summaryTitle,
  renderLegend = () => null,
 }) {
  return (
    <Wrapper>
      <div className="chart">
        <div className="summary">
          {summaryPercent}%
          <div className="title">{summaryTitle}</div>
        </div>
        <Doughnut
          data={data}
          options={chartOptions}
        />
      </div>

      <div className="legendTable">
        {renderLegend()}
      </div>
    </Wrapper>
  );
}
