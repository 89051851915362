import wurd, { WurdText } from 'wurd-react';
import { Link } from 'react-router-dom';
import * as helpers from '../../helpers';
import LangText from 'components/lang-text';
import { useItemLoader } from 'hooks';

import UnitLoader from '../loaders/unit';
import SidebarItem from './index';
import Placeholder from './loading-placeholder';


function SidebarUnitMain({
  unit,
  path = unit.rentalId ? `/unit-rentals/${unit.rentalId}` : `/units/${unit.id}`,
  onClickEdit,
  title = null,
}) {
  const { item: site } = useItemLoader('sites', unit.siteId);

  return (
    <SidebarItem
      image={site?.image || "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-10 -10 20 20' stroke='%230003'%3E%3Cpath d='M-6,0L6,0' /%3E%3C/svg%3E"}
      typeTitle={title || <LangText obj={site?.title || '–'} />}
      itemTitle={(
        <div>
          <Link to={path}><WurdText id="common.phrases.unit" vars={{ name: unit.name?.toUpperCase() }} /></Link>&nbsp;&nbsp;
          <small style={{ color: '#aaa' }}>{helpers.unit.size(unit, site?.measure)}</small>
        </div>
      )}
      onClickEdit={onClickEdit}
    />
  );
}


export default function SidebarUnit({
  id,
  unit,
  path,
  onClickEdit,
  title = null,
}) {
  if (id) {
    return (
      <UnitLoader
        id={id}
        loading={<Placeholder typeTitle={wurd.text('common.sidebarItems.unit')} />}
      >
        {(loadedUnit) => (
          <SidebarUnitMain
            title={title}
            unit={loadedUnit}
            path={path}
            onClickEdit={onClickEdit}
          />
        )}
      </UnitLoader>
    );
  }

  if (unit) {
    return (
      <SidebarUnitMain
        unit={unit}
        path={path}
        onClickEdit={onClickEdit}
        title={title}
      />
    );
  }

  return (
    <Placeholder typeTitle={wurd.text('common.sidebarItems.unit')} />
  );
}
