import * as actions from 'actions';
import { date, currency, confirm } from 'helpers/ui';
import moment from 'moment-timezone';

import Markdown from 'components/markdown';
import Alert from 'components/alert';
import ApiButton from 'components/api-button';


export default function UpdatePriceJob({
  cms,
  job,
  onChange,
}) {
  cms = cms.block('jobs.updatePrice');

  const { noticeDate, startDate, oldPrice, newPrice } = job.result;

  async function cancelJob() {
    if (!confirm(cms.text('confirmCancel'))) return;
    
    await actions.jobs.cancel(job.id);
    onChange();
  }

  const today = moment().format('YYYY-MM-DD');
  const hasFutureNotice = noticeDate && noticeDate > today;
  const hasPastNotice = noticeDate && noticeDate < today;

  return (
    <cms.Object keys="title,startDate,noticeDate_future,noticeDate_past,confirmCancel">
      <Alert
        type="info"
        icon="far fa-dollar-sign"
        actions={(
          <ApiButton
            bsType="link"
            onClick={cancelJob}
          >
            <i className="far fa-times" />
          </ApiButton>
        )}
      >
        <Markdown>
          {cms.text('title', {
            startDate: date(startDate),
            oldPrice: currency(oldPrice),
            newPrice: currency(newPrice),
          })}
        </Markdown>

        {hasFutureNotice && (
          <Markdown>
            {cms.text('noticeDate_future', {
              noticeDate: date(noticeDate),
            })}
          </Markdown>
        )}

        {hasPastNotice && (
          <Markdown>
            {cms.text('noticeDate_past', {
              noticeDate: date(noticeDate),
            })}
          </Markdown>
        )}
        
        <Markdown>
          {cms.text('startDate', {
            startDate: date(startDate),
            oldPrice: currency(oldPrice),
            newPrice: currency(newPrice),
          })}
        </Markdown>
      </Alert>
    </cms.Object>
  );
}
