import moment from 'moment-timezone';
import wurd from 'wurd-react';
import _ from 'lodash';

import * as helpers from '.';
import * as tag from './unit_tag';
import * as charges from '../plugins/charges/helpers';
import * as actions from '../actions/general';
import processCustomFields from '../plugins/custom-fields/actions';


export { tag, charges };

export const states = [
  'available',
  'blocked',
  'reserved',
  'occupied',
  'overdue',
];


export const stateColors = {
  available: '#60C060',
  blocked: '#ababab',
  occupied: '#2da8e5',
  overdue: '#d9534f',
  reserved: '#f0ad4e',
};


export const stateIcons = {
  available: 'fas fa-warehouse',
  blocked: 'fas fa-ban',
  occupied: 'fas fa-user',
  overdue: 'fas fa-user',
  reserved: 'fas fa-user',
};

export const stateLabelTypes = {
  archived: 'secondary',
  available: 'success',
  blocked: 'secondary',
  occupied: 'info',
  overdue: 'danger',
  reserved: 'warning',
  submitted: 'secondary',
  cancelled: 'secondary',
  abandoned: 'secondary',
  ended: 'secondary',
};


export function getStateTitles() {
  return wurd.get('unit._states') || {};
}


/**
 * Returns the size of the unit in the form "5' x 5' x 4"
 *
 * @param {Object} unit
 * @param {'m'|'ft'} [measure]
 * @param {Boolean} [includeHeight]
 *
 * @param {String}
 */
export function size(unit, measure = unit.measure, includeHeight = false) {
  const dimensions = [unit.width, unit.length];

  if (includeHeight) dimensions.push(unit.height);

  if (measure === 'm') {
    return `${dimensions.join(' x ')} m`;
  }

  return `${dimensions.join("' x ")}'`; // default to ft
}

export function area(unit, measure = null) {
  const num = Math.round(unit.width * unit.length * 100) / 100;

  return measure ? `${num}${measure}²` : num;
}

export function volume(unit, measure = null) {
  const num = Math.round(unit.width * unit.length * unit.height * 100) / 100;

  return measure ? `${num}${measure}³` : num;
}


export function expandNameRange(str = '') {
  const ranges = str.split(/[,; \n\s]/g).map(v => v.trim()).filter(Boolean);

  return ranges.flatMap(range => {
    const [start, end] = range.split(':');

    if (!end) return [start];

    const startNum = start.match(/\d+/), endNum = end.match(/\d+/);
    if (!startNum || !endNum) return [];

    const startValue = +startNum[0], endValue = +endNum[0];
    const unitNames = Array.from({ length: Math.min(1000, endValue - startValue + 1) }, (_, i) => start.replace(/\d+/, v => (startValue + i).toString().padStart(v.length, 0)));

    return [...new Set(unitNames)]; // dedup
  });
}


/**
 * Formats unit as CSV
 *
 * @param {Object} unit
 * @return {String} csv
 */
export function toCsv(unit) {
  const settings = helpers.settings.get();
  const site = unit.site;
  const unitType = unit.type || site?.unitTypes.find(t => t.id === unit.typeId);

  return {
    id: unit.id,
    siteId: unit.siteId,
    ...site && _.mapKeys(helpers.site.toCsv(site), (v, k) => `site.${k}`),
    typeId: unit.typeId,
    ...unitType && _.mapKeys(helpers.unitType.toCsv(unitType), (v, k) => `type.${k}`),
    name: unit.name,
    floor: unit.floor,
    width: unit.width,
    length: unit.length,
    height: unit.height,
    measure: unit.measure,
    area: helpers.unit.area(unit),
    volume: helpers.unit.volume(unit),
    featureIds: unit.featureIds?.join(','),
    defaultPrice: unit.defaultPrice,
    defaultDeposit: unit.defaultDeposit,
    state: unit.state,
    ownerId: unit.ownerId,
    ...unit.owner && _.mapKeys(helpers.user.toCsv(unit.owner), (v, k) => `owner.${k}`),
    rentalId: unit.rentalId,
    ...unit.rental && _.mapKeys(helpers.unitRental.toCsv(unit.rental), (v, k) => `rental.${k}`),
    labels: unit.labels?.join(','),
    ...Object.fromEntries(
      settings.unitCustomFields?.sort((a,b) => a.code.localeCompare(b.code)).map(({ code, title }) => {
        const value = unit.customFields?.[code];
        return [`customFields.${code}`, value?.url || value];
      })
    ),
    created: unit.created,
    updated: unit.updated,
  };
}


export async function fetchDetailed(params) {
  const units = await actions.fetch('units', params, { skipCache: true });

  const users = await actions.fetch('users', { ids: units.map(unit => unit.ownerId).filter(Boolean), include: 'billing,customFields' }, { skipCache: true }).then(users => new Map(users.map(o => [o.id, o])));
  const sites = await actions.fetch('sites', { ids: units.map(unit => unit.siteId), include: 'customFields' }, { skipCache: true }).then(sites => new Map(sites.map(o => [o.id, o])));
  const types = await actions.fetch('unit-types', { ids: units.map(unit => unit.typeId), include: 'customFields' }, { skipCache: true }).then(types => new Map(types.map(o => [o.id, o])));

  return units.map(unit => {
    const owner = users.get(unit.ownerId);
    const site = sites.get(unit.siteId);
    const type = types.get(unit.typeId);
    return { ...unit, owner, site, type };
  });
}


export async function fromCsv({ _owner_email, site, _site_code = site?.code, type, _type_code = type?.code, ...unit }, params) {
  if (_site_code && _site_code !== params.idOrCode) throw new Error(`site "${_site_code}" doesn't match current site "${params.idOrCode}"`);

  if (unit.customFields) {
    unit.customFields = await processCustomFields(unit.customFields, 'units');
  }

  site = site || await actions.fetchOneWithCache('sites', unit.siteId || _site_code, !unit.siteId && { altIdKey: 'code' });
  const siteId = unit.siteId || site?.id;
  if (!siteId) throw new Error(`site ${unit.siteId || _site_code} not found`);

  const typeId = unit.typeId || (await actions._fetch(`sites/${siteId}/unit-types/${_type_code}`))?.id;
  if (!typeId) throw new Error(`unit type ${unit.typeId || _type_code} not found`);

  const ownerId = unit.ownerId || _owner_email && (await actions.fetchOneWithCache('users', _owner_email, { altIdKey: 'email' }))?.id;
  if (!ownerId && _owner_email) throw new Error(`owner ${_owner_email} not found`);

  return {
    ..._.omit(unit, ['created', 'updated']),
    siteId,
    typeId,
    ownerId,
    featureIds: (unit.featureIds || undefined)?.split(','),
    labels: (unit.labels || undefined)?.split(','),
  };
}

export async function fromCsvUpdate(unit) {
  if (unit.customFields) {
    unit.customFields = await processCustomFields(unit.customFields, 'units');
  }
  return {
    ..._.omit(unit, ['id', 'siteId', 'ownerId', 'rentalId', 'created', 'updated']),
    featureIds: (unit.featureIds || undefined)?.split(','),
    labels: (unit.labels || undefined)?.split(','),
  };
}