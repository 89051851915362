import crudList from '../../actions/plugins/crud-list';


function formatData(data) {
  if (typeof data.enum === 'string' && data.enum.length) {
    data.enum = data.enum.split(/[\n,]/).filter(Boolean);
  }
  
  return data;
}


export default function (endpoint) {
  const handlers = crudList(endpoint);

  return {
    ...handlers,

    create(data) {
      return handlers.create(formatData(data));
    },

    update(itemId, data) {
      return handlers.update(itemId, formatData(data));
    }
  }
}
