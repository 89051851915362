import wurd from 'wurd-react';

import { ModalForm, Field, MultiLangText } from '../../form2';


export default function ({ mode, ...props }) {
  return (
    <ModalForm
      wurdSection="business.footerLinks"
      mode={mode}
      {...props}
    >
      {(getProps) => (
        <>
          <Field {...getProps('text')} autoFocus>
            <MultiLangText />
          </Field>

          <Field {...getProps('url')}>
            <MultiLangText />
          </Field>
        </>
      )}
    </ModalForm>
  );
}
