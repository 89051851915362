import wurd from 'wurd-react';

import api from '../utils/api';
import store from '../store';
import errHandler from './_err-handler';
import crudList from './plugins/crud-list';
import labelsCrudList from '../plugins/labels/settings-crud-list';
import customFieldsCrudList from '../plugins/custom-fields/settings-crud-list';
import processCustomFields from '../plugins/custom-fields/actions';


const actions = {};

actions.accountingCodes = crudList(`/v1/admin/settings/accounting-codes`);
actions.plans = crudList(`/v1/admin/settings/plans`);
actions.userCharges = crudList(`/v1/admin/settings/user-charges`);
actions.promos = crudList(`/v1/admin/settings/promos`);
actions.unitTags = crudList(`/v1/admin/settings/unit-tags`);
actions.webhooks = crudList(`/v1/admin/settings/webhooks`);
actions.webtasks = crudList(`/v1/admin/settings/webtasks`);
actions.unitPromos = crudList(`/v1/admin/settings/unitPromos`);
actions.apiKeys = crudList(`/v1/admin/settings/apiKeys`);
actions.areas = crudList(`/v1/admin/settings/areas`);
actions.items = crudList(`/v1/admin/settings/items`);
actions.products = crudList(`/v1/admin/settings/products`);
actions.customDomains = crudList(`/v1/admin/settings/customDomains`);
actions.manualPaymentMethods = crudList(`/v1/admin/settings/manualPaymentMethods`);
actions.footerLinks = crudList(`/v1/admin/settings/footerLinks`);

actions.invoiceLabels = labelsCrudList(`/v1/admin/settings/invoiceLabels`);
actions.itemLabels = labelsCrudList(`/v1/admin/settings/itemLabels`);
actions.jobLabels = labelsCrudList(`/v1/admin/settings/jobLabels`);
actions.siteLabels = labelsCrudList(`/v1/admin/settings/siteLabels`);
actions.unitLabels = labelsCrudList(`/v1/admin/settings/unitLabels`);
actions.unitTypeLabels = labelsCrudList(`/v1/admin/settings/unitTypeLabels`);
actions.userLabels = labelsCrudList(`/v1/admin/settings/userLabels`);
actions.valetOrderLabels = labelsCrudList(`/v1/admin/settings/valetOrderLabels`);

actions.businessCustomFields = customFieldsCrudList(`/v1/admin/settings/businessCustomFields`);
actions.userCustomFields = customFieldsCrudList(`/v1/admin/settings/userCustomFields`);
actions.valetOrderCustomFields = customFieldsCrudList(`/v1/admin/settings/valetOrderCustomFields`);
actions.invoiceCustomFields = customFieldsCrudList(`/v1/admin/settings/invoiceCustomFields`);
actions.itemCustomFields = customFieldsCrudList(`/v1/admin/settings/itemCustomFields`);
actions.siteCustomFields = customFieldsCrudList(`/v1/admin/settings/siteCustomFields`);
actions.unitCustomFields = customFieldsCrudList(`/v1/admin/settings/unitCustomFields`);
actions.unitTypeCustomFields = customFieldsCrudList(`/v1/admin/settings/unitTypeCustomFields`);
actions.unitRentalCustomFields = customFieldsCrudList(`/v1/admin/settings/unitRentalCustomFields`);

actions.set = function (settings) {
  store.set({ settings });
};

actions.fetch = function () {
  return api.get('/v1/admin/settings?include=*')
    .then(res => {
      const settings = res.data;

      store.set({ settings });

      return settings;
    })
    .catch(errHandler);
};

actions.createItem = function (item) {
  return api.post(`/v1/admin/settings/items`, item)
    .then(actions.fetch)
    .catch(errHandler)
};

actions.updateItem = function (item) {
  return api.put(`/v1/admin/settings/items/${item.id}`, item)
    .then(actions.fetch)
    .catch(errHandler)
};

actions.createStoreItem = function (item) {
  return api.post(`/v1/admin/settings/store-items`, item)
    .then(actions.fetch)
    .catch(errHandler)
};

actions.updateStoreItem = function (item) {
  return api.put(`/v1/admin/settings/store-items/${item.id}`, item)
    .then(actions.fetch)
    .catch(errHandler)
};

actions.createArea = function (area) {
  return api.post(`/v1/admin/settings/areas`, area)
    .then(actions.fetch)
    .catch(errHandler)
};

actions.updateArea = function (area) {
  return api.put(`/v1/admin/settings/areas/${area.id}`, area)
    .then(actions.fetch)
    .catch(errHandler)
};

actions.update = async function update(data) {
  if (data.customFields) {
    data.customFields = await processCustomFields(data.customFields, 'business');
  }
  return api.put(`/v1/admin/settings?include=userCustomFields,businessCustomFields,valetOrderCustomFields,customFields`, data)
    .then(res => {
      const settings = res.data;

      const { emailTemplates, emailMutes } = data;

      const emailSettings = store.get('emailSettings');

      store.set({
        settings: { ...store.get('settings'), ...settings },
        emailSettings: {
          ...emailSettings,
          ...emailTemplates && {
            templates: { ...emailSettings?.templates, ...emailTemplates },
          },
          ...emailMutes && {
            mutes: { ...emailSettings?.mutes, ...emailMutes },
          },
        }
      });

      return settings;
    })
    .catch(errHandler);
};

actions.emails = { // todo move this in ./emails
  fetch() {
    return api.get('/v1/admin/settings/emails')
      .then(async res => {
        const langKeys = store.get('settings')?.languages || ['en'];

        const emailWurds = Object.fromEntries(
          langKeys.map(lang => [lang, new wurd.Wurd('storeganise-emails2', { lang, storageKey: 'emailContent' })])
        );

        const variables = res.data.editorVars /*old name, can be removed later*/ || res.data.variables;

        const templatesNames = [...Object.keys(variables), 'general_user_invoiceReminder1'];

        const loadKeys = [...new Set(templatesNames.map(name => name.split('_', 1)[0]))];
        await Promise.all(
          Object.values(emailWurds).map(w => w.load(loadKeys.join(',')))
        );

        const defaultTemplates = Object.fromEntries(
          templatesNames.map(name => [
            name,
            Object.fromEntries(langKeys.map(lang => [lang, emailWurds[lang].get(name.replace(/_/g, '.'))]))
          ])
        );

        const emailSettings = {
          defaultTemplates,
          ...res.data, // {variables: {[templateName]: Array}, templates: {[templateName]: MultiLangString}, disabled: {[templateName]: Boolean}}
          variables,
        };

        store.set({ emailSettings });

        return emailSettings;
      })
      .catch(errHandler);
  },
};

actions.esign = {
  fetch({ siteId } = {}) {
    return api.get('/v1/admin/settings/esign', { params: { siteId } })
      .then(({ data }) => {
        const settings = store.get('settings');
        // store.set({
        //   settings: {
        //     ...store.get('settings'),
        //     esign: res.data,
        //   },
        // });

        // defaults template to en
        return {
          ...data,
          defaultMoveInAgreementTemplate: Object.fromEntries(
            settings.languages.map(lang => [lang, data.defaultMoveInAgreementTemplate[lang] || data.defaultMoveInAgreementTemplate.en])
          )
        };
      })
      .catch(errHandler);
  }
};

actions.website = {
  fetch(path) {
    return api.get(`/v1/admin/settings/website${path !== undefined ? `/${path || '-'}` : ''}`)
      .then(res => res.data);
  },
  update(path, data) {
    return api.put(`/v1/admin/settings/website/${path || '-'}`, data)
      .then(res => res.data);
  },
  delete(path) {
    return api.delete(`/v1/admin/settings/website/${path || '-'}`)
      .then(res => res.data);
  },
}

export default actions;
