import moment from 'moment-timezone';
import wurd from 'wurd-react';
import styled from '@emotion/styled';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import { useQuery } from 'react-query';

import * as actions from 'actions';
import * as helpers from 'helpers';
import { currentLang } from 'utils/language';
import { hasRole } from 'helpers/auth';

import Section from 'components/section';
import RadioList from 'components/form2/radiolist';
import Alert from 'components/alert';
import LangText from 'components/lang-text';
import SettingsForm2 from '../form2';
import Input from '../input';
import CustomFieldsSection from 'plugins/custom-fields/section';
import SectionCrudList from 'components/section_crud-list';
import FooterLinksForm from './footerLinks-form';

const cms = wurd.block('settings');

const DualInput = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '0 1rem',
  'div,p': { display: 'contents' }
});

export default function GeneralSettings({ settings, modal }) {
  const formatDate = (format) => moment().locale(currentLang).format(format);

  const { data: currencies } = useQuery('currencies', () => import('currency-codes').then(m => m.default));

  return (
    <>
      <Section cms={cms.block('general')}>
        <SettingsForm2
          wurdSection="settings.inputs"
          initialValue={settings}
          onSubmit={(data, dataPatches) => actions.business.update(dataPatches)}
        >
          {({ fieldProps, inputProps, formValue }) => {
            return (
              <>
                <Input {...fieldProps('companyName')} required />
                <Input {...fieldProps('siteUrl')} required />
                <Input {...fieldProps('helpUrl')} />

                <cms.Object
                  type={FormGroup}
                  controlId="helpPhone"
                  id="inputs.helpPhone"
                  keys="label,full.placeholder,full.help,display.placeholder,display.help"
                >
                  <ControlLabel className="col-sm-3">{cms.text('inputs.helpPhone.label')}</ControlLabel>
                  <DualInput className="col-sm-9">
                    <input className="form-control" {...inputProps('helpPhone.full')} placeholder={cms.text('inputs.helpPhone.full.placeholder')} type="tel" />
                    <input className="form-control" {...inputProps('helpPhone.display')} placeholder={cms.text('inputs.helpPhone.display.placeholder')} />

                    <span className="help-block">{cms.text('inputs.helpPhone.full.help')}</span>
                    <span className="help-block">{cms.text('inputs.helpPhone.display.help')}</span>
                  </DualInput>
                </cms.Object>

                <hr />

                <h4><cms.Text id="general.signup" /></h4>
                <Input {...fieldProps('termsUrl')} />
                <Input {...fieldProps('requireAgreeTerms')} type="select" wurdObjectKeys="label,help,no,yes">
                  <option value={false}>{cms.text('inputs.requireAgreeTerms.no')}</option>
                  <option value={true}>{cms.text('inputs.requireAgreeTerms.yes')}</option>
                </Input>
                <Input {...fieldProps('captcha')} type="select" wurdObjectKeys="label,help,none,recaptcha">
                  <option value="">{cms.text('inputs.captcha.none')}</option>
                  <option value="recaptcha">{cms.text('inputs.captcha.recaptcha')}</option>
                </Input>

                <hr />

                <h4><cms.Text id="general.dateFormats" /></h4>
                <Input {...fieldProps('dateFormats.long')} type="select" required wurdKey="dateFormats.long">
                  {['dddd, D MMMM', 'D MMMM', 'D MMMM YYYY', 'YYYY-MM-DD', 'MMMM D, YYYY'].map((format) => (
                    <option key={format} value={format}>{formatDate(format)}</option>
                  ))}
                </Input>
                <Input {...fieldProps('dateFormats.short')} type="select" required wurdKey="dateFormats.short">
                  {['YYYY-MM-DD', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MMM D, YYYY'].map((format) => (
                    <option key={format} value={format}>{formatDate(format)} ({format})</option>
                  ))}
                </Input>
                <Input {...fieldProps('dateFormats.time')} type="select" required wurdKey="dateFormats.time">
                  {['HH:mm', 'h:mma'].map((format) => (
                    <option key={format} value={format}>{formatDate(format)}</option>
                  ))}
                </Input>

                <Input {...fieldProps('distanceUnit')} type="select" wurdObjectKeys="label,help,km,mi">
                   <option value="">{cms.text('inputs.distanceUnit.km') || 'km'}</option>
                   <option value="mi">{cms.text('inputs.distanceUnit.mi') || 'mi'}</option>
                </Input>
              </>
            );
          }}
        </SettingsForm2>
      </Section>

      <SectionCrudList
        cms={cms.block('footerLinks')}
        items={settings.footerLinks}
        Form={FooterLinksForm}
        actions={actions.business.footerLinks}
        onChange={actions.business.fetch}
      >
        {link => (
          <LangText>{link.text}</LangText>
        )}
      </SectionCrudList>

      <CustomFieldsSection
        cms={cms.block('business_customFields')}
        modelName="business"
        model={settings}
        onChange={actions.business.fetch}
        onSubmit={actions.business.update}
        modal={modal}
      />
    </>
  );
}
