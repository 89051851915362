import { pick } from 'lodash';
import wurd, { WurdText } from 'wurd-react';
import { useNavigate, useParams } from 'react-router-dom';

import * as actions from 'actions';

import Section from 'components/section';
import { Field } from 'components/form2';
import { useApiForm } from 'hooks';

const fields = [
  'code',
  'hidden',
  'enableUnitBooking',
  'measure',
  'area_gross',
  'area_net',
  'area_netLeasable',
  'area_occupied',
  'revenue_potential',
  'revenue_actual',
];


export default function SiteAdvancedDetailsSettings({ site, fetchSite, ...rest }) {
  const params = useParams();
  const navigate = useNavigate();

  const { fieldProps, submit, loading, dirty, formValue } = useApiForm({
    wurdSection: 'site',
    initialValue: pick(site, fields),
    onSubmit: formData => actions.sites.update(site.id, formData),
    onSuccess: (updatedSite) => {
      if (updatedSite.code !== site.code && !/^[a-z0-9]{24}$/.test(params.idOrCode)) {
        return navigate(`/sites/${updatedSite.code}/advanced-settings`, { replace: true });
      }

      return fetchSite();
    },
    onError: window.alert,
    ...rest
  });

  return (
    <Section cms={wurd.block('siteView.advanced')}>
      <Field {...fieldProps('code')} />

      <Field {...fieldProps('hidden')} type="checkbox" />
      
      <Field {...fieldProps('enableUnitBooking')} type="checkbox" />

      <Field {...fieldProps('measure')} required>
        <select>
          <option value="ft">Feet</option>
          <option value="m">Meters</option>
        </select>
      </Field>

      <Field {...fieldProps('area_gross')}
        type="number"
        pattern="^\d*(\.\d{0,2})?$"
      />

      <Field {...fieldProps('area_net')}
        type="number"
        pattern="^\d*(\.\d{0,2})?$"
      />

      <button
        className="btn btn-primary pull-right"
        type="button"
        disabled={loading || !dirty}
        onClick={submit}
      >
        {loading
          ? <i className="fa fa-spinner fa-spin" />
          : <WurdText id="common.saveBtn" />
        }
      </button>
    </Section>
  );
}
