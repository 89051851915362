import { WurdText } from 'wurd-react';
import moment from 'moment-timezone';

import * as helpers from 'helpers';

import UnitRentalLoader from 'components/loaders/unit-rental';
import UnitLoader from 'components/loaders/unit';
import UserLoader from 'components/loaders/user';
import ShortLabel from 'components/short-label';
import SiteLoader from 'components/loaders/site';
import JobStateLabel from './job-state-label';
import LabelsListCell from 'plugins/labels/list';

const { stateColors } = helpers.unit;

const styles = {
  jobIcon: { color: stateColors.occupied },
  miniIcon: { color: '#aaa', _fontSize: 18, _marginTop: 6 },
};


export default function UnitMoveInJobListItem({
  job,
  hideOwner,
  hideUnit,
  hideSite,
}) {
  const { noticeDate, startDate, ownerId, unitRentalId, oldPrice, newPrice } = job.result;

  return (
    <>
      <div role="cell" style={{ width: 30 }}>
        <div><i className="fas fa-fw fa-dollar-sign" style={styles.jobIcon} /></div>
      </div>

      <div role="cell">
        <div>
          <strong>
            <WurdText id="unitJobList.filters.type.unitRental_updatePrice" />:&nbsp;
            {!hideUnit && unitRentalId && (
              <UnitRentalLoader id={unitRentalId}>
                {(unitRental) => (
                  <UnitLoader id={unitRental.unitId}>
                    {(unit) => (
                      <>
                        {unit.name}
                        {!hideSite && (
                          <span>, <SiteLoader id={unit.siteId}>{site => helpers.ui.getLangText(site.title)}</SiteLoader></span>
                        )}
                      </>
                    )}
                  </UnitLoader>
                )}
              </UnitRentalLoader>
            )}
          </strong>
        </div>

        <div>
          {!hideOwner && (
            <UserLoader id={ownerId}>
              {(user) => user.name}
            </UserLoader>
          )}
        </div>
      </div>

      <div role="cell">
        {noticeDate && (
          <div title="Notice date">
            <i className="far fa-fw fa-envelope" style={styles.miniIcon} />
            {` `}
            {noticeDate < moment().format('YYYY-MM-DD')
              ? (
                <span style={{ textDecoration: 'line-through', color: '#ccc' }}>{helpers.ui.date(noticeDate)}</span>
              ) : (
                <span>{helpers.ui.date(noticeDate)}</span>
              )
            }
          </div>
        )}
        <div title="Price change date">
          <i className="far fa-fw fa-clock" style={styles.miniIcon} /> {helpers.ui.date(startDate)}
        </div>
      </div>

      <div role="cell" style={{ textAlign: 'right' }}>
        <div title="Price change">
          {helpers.ui.currency(oldPrice)} → {helpers.ui.currency(newPrice)}
        </div>
        <div><JobStateLabel job={job} /></div>
        {job.error && (
          <div>
            <ShortLabel bsType="danger" width="min(200px, 25vw)">{job.error}</ShortLabel>
          </div>
        )}
      </div>

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={job.labels} />
      </div>
    </>
  );
}
