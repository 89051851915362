/* eslint-disable jsx-a11y/anchor-is-valid */

import wurd from 'wurd-react';

import { getLuminance } from 'helpers/ui';

import Section from '../../section';
import SettingsForm from '../form';
import Input from '../input';
import ImageUploader from '../image-uploader';
import Logo from 'components/logo';


const cms = wurd.block('settings.brand');


export default function BrandSettings({ settings }) {
  const { brand = {} } = settings || {};

  function renderPreview() {
    const styles = {
      previewPanel: {
        border: '1px solid #eee',
        padding: 10
      },
      navbar: {
        backgroundColor: '#fff',
        padding: '0 0 10px 0',
        marginBottom: 10,
        borderBottom: '1px solid #eee',
      },
      logo: {
        height: 25,
      },
      navbarLink: {
        float: 'right',
        color: brand.linkColor
      },
      header: {
        color: '#000',
      },
      text: {
        color: '#000',
      },
      textLink: {
        color: brand.linkColor,
        cursor: 'pointer'
      },
      button: {
        backgroundColor: brand.linkColor,
        color: getLuminance(brand.linkColor) > .5 ? '#000' : '#fff',
        borderRadius: 2,
        padding: '4px 8px',
        cursor: 'pointer',
      },
      outline: {
        backgroundColor: brand.linkColor,
        border: 0,
        color: '#fff',
        fontWeight: 600,
      }
    };

    return (
      <div style={styles.previewPanel}>
        <div style={styles.navbar}>
          <Logo style={styles.logo} />
          <a style={styles.navbarLink}>Navigation link</a>
        </div>

        {/* <h4 style={styles.header}>Header text</h4> */}
        <p style={styles.text}>Here is some sample text <a style={styles.textLink}>with a link</a>.</p>

        <a className="btn btn-link btn-flat btn-sm" style={styles.button}>Button text</a>
      </div>
    );
  }


  return (
    <Section cms={cms}>
      <h4><cms.Text id="preview" /></h4>
      {renderPreview()}

      <hr />

      <SettingsForm name="brand" defaultValue={brand}>
        {['linkColor'].map(item =>
          <Input key={item} name={item} required wurdKey={`brand.${item}`} style={{ position: 'relative' }} />
        )}
        <ImageUploader
          name="logo"
          style={{ width: 200, backgroundColor: '#fff' }}
          wurdKey="settings.inputs.brand.logo"
        />
        <ImageUploader
          name="icon"
          style={{ width: 50 }}
          wurdKey="settings.inputs.brand.icon"
        />
      </SettingsForm>
    </Section>
  );
}
