import { WurdText } from 'wurd-react';

import * as helpers from '../../helpers';
import * as actions from '../../actions';

import { pick } from 'lodash';
import { ModalForm } from '../../components/form2';
import CustomField from './field';
import processCustomFields from './actions';


export default ({ modelName, model, modal, onChange = () => {}, ...props }) => {
  const settings = helpers.settings.get();
  const endpoint = modelName.replace(/[A-Z]/, s => `-${s[0].toLowerCase()}`).replace(/([^s])$/, '$1s');

  return (
    <ModalForm
      wurdSection={`${modelName}.customFields`}
      initialValue={pick(model, settings[`${modelName}CustomFields`].map(({ code }) => `customFields.${code}`))}
      onSubmit={async (fullData, dataPatchesExtended, dataPatches) => {
        // submit only patches because file cfs are in name/url read format, but saved in name/path format, so unless they're modified don't send them
        if (dataPatches.customFields) {
          dataPatches.customFields = await processCustomFields(dataPatches.customFields, endpoint);
        }
        return actions.update(endpoint, model.id, dataPatches, { include: 'customFields' });
      }}
      onSuccess={(r) => { onChange(r); modal.close(); }}
      submitButton={<WurdText id="common.modalForm.update" />}
      closeModal={modal.close}
      closeIcon={null}
      title={null}
      {...props}
    >
      {(getProps) => (
        <div>
          {settings[`${modelName}CustomFields`].sort(helpers.ui.sortByCode).map(({ code, ...cfProps }) => (
            <CustomField
              key={code}
              {...getProps(`customFields.${code}`)}
              {...cfProps}
            />
          ))}
        </div>
      )}
    </ModalForm>
  );
}