import { ModalForm, Field, MultiLangText } from '../../form2';


export default function BusinessAccoutingCodeForm(props) {
  return (
    <ModalForm
      wurdSection="business.accountingCode"
      {...props}
    >
      {(getProps) => (
        <>
          <Field {...getProps('code')} required />

          <Field {...getProps('title')} required>
            <MultiLangText />
          </Field>

          <Field {...getProps('taxDesc')} />

          <Field {...getProps('taxPct')} type="number" step="any" />
        </>
      )}
    </ModalForm>
  );
}
