import Activity from 'components/activity';


export default function AddonConfigHistory({
  cms,
  modal,
  refetch,
  addonConfig,
}) {  
  return (
    <>
      <Activity
        context="addonConfig"
        endpoint={`/addon-configs/${addonConfig.id}/actions`}
      />
    </>
  );
}
