import { useParams } from 'react-router-dom';
import { useModal, useItemLoader } from '../hooks';

import NotFound from '../components/not-found';
import Spinner from '../components/spinner';
import SiteDetailInnerPage from '../components/site-detail/page';


export default function SiteDetailPage() {
  const { idOrCode, section = 'overview' } = useParams();

  const modal = useModal();

  const { item: site, isLoading, refetch } = useItemLoader('sites', idOrCode, {
    altIdKey: 'code',
    maxAge: 0,
    include: ['customFields', 'accessTypes', ...(section === 'overview' || section === 'details') ? ['submitBookingText'] : []],
  });

  if (!site) {
    return isLoading ? <Spinner /> : <NotFound />;
  }

  return (
    <SiteDetailInnerPage
      site={site}
      fetchSite={refetch}
      modal={modal}
    />
  );
}
