import wurd from 'wurd-react';

import { ModalForm, Field } from '../../form2';
import DynamicLoader from 'components/dynamic-loader';
import EventsChecklist from '../developer/events-checklist';

const CodeEditor = DynamicLoader(() => import('components/code-editor'));


const placeholder = `export default async function ({ api, context, data, event }) {
  await api.put(\`/v1/admin/users/\${data.userId}\`, { customFields: { account_type: 'individual' } });
}`;

export default function WebtaskForm(props) {

  return (
    <ModalForm
      wurdSection="business.webtasks"
      {...props}
    >
      {(getProps, formValue) => (
        <>
          <Field {...getProps('name')} />
          <Field {...getProps('events')} className={null}>
            <EventsChecklist />
          </Field>
          <Field {...getProps('roles')}>
            <select multiple style={{ height: 50 }}>
              {['admin', 'manager'].map(role => (
                <option key={role} value={role}>{wurd.text(`user._roles.${role}`)}</option>
              ))}
            </select>
          </Field>

          <Field {...getProps('handler')} required className={null}>
            <CodeEditor lang="javascript" placeholder={placeholder} />
          </Field>
        </>
      )}
    </ModalForm>
  );
}
