import wurd from 'wurd-react';

import store from '../../store';
import Section from '../../components/section';
import CustomFieldsList from 'plugins/custom-fields/list';
import CustomFieldsEditModal from 'plugins/custom-fields/modal';


const cms = wurd.block('invoiceView.customFields');

export default function CustomFieldsSection({ cms, modelName, model, modal, onChange, ...props }) {
  const { settings } = store.get();

  function edit() {
    modal.open(<CustomFieldsEditModal modelName={modelName} model={model} modal={modal} onChange={onChange} {...props} />)
  }

  return settings[`${modelName}CustomFields`]?.length > 0 && (
    <Section
      cms={cms}
      onClickEdit={model?.deleted ? null : edit}
    >
      <CustomFieldsList modelName={modelName} model={model} />
    </Section>
  );
}