import wurd from 'wurd-react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

import { apiUrl } from 'config';
import * as actions from 'actions';
import * as helpers from 'helpers';

import ShareModal from './share-modal';

const { hasRole } = helpers.auth;
const { redirectBack } = helpers.ui;

const cms = wurd.block('invoiceView.menu');

const wurdIds = [
  'sendReceipt',
  'makeDraft',
  'share',
  'download',
  'markOverdue',
  'unmarkOverdue',
  'confirmOverdue',
  'successOverdue',
  'delete',
  'confirmDelete',
];


export default function InvoiceDetailMenu({
  invoice,
  modal,
  onChange,
  tasksPlugin,
}) {
  function makeDraft() {
    return actions.invoices.update(invoice.id, { state: 'draft' });
  }

  function deleteInvoice() {
    if (!window.confirm(cms.text('confirmDelete'))) return null;

    return actions.invoices.delete(invoice.id)
      .then(onChange);
  }

  function sendReceipt() {
    return actions.invoices.send(invoice.id);
  }

  function shareInvoice() {
    modal.open(
      <ShareModal
        invoice={invoice}
        cms={cms.block('shareModal')}
        modal={modal}
      />,
    );
  }

  function toggleOverdue() {
    if (!window.confirm(cms.text('confirmOverdue'))) return null;
    const currentUser = helpers.auth.getCurrentUser();
    return actions.jobs.create(currentUser.id, 'invoice_overdue', {
      invoiceId: invoice.id,
      overdue: !invoice.overdue,
    })
      .then(onChange)
      .catch(err => helpers.ui.alert(err.message));
  }

  function getMenuItems() {
    const items = [];

    if (invoice.state !== 'draft') {
      items.push(
        <MenuItem
          key="makeDraft"
          onSelect={makeDraft}
          disabled={!helpers.auth.hasPermission('invoice:markDraft')}
          title={wurd.text('common.errMsg.Forbidden-missing-invoice-markDraft-permission')}
        >
          <i className="fas fa-fw fa-edit" /> {cms.text('makeDraft')}
        </MenuItem>,
      );
    }

    if (invoice.state === 'paid') {
      items.push(
        <MenuItem key="sendReceipt" onSelect={sendReceipt}>
          <i className="fas fa-fw fa-receipt" /> {cms.text('sendReceipt')}
        </MenuItem>,
      );
    }
    if (hasRole('manager') && (invoice.overdue || ['sent', 'failed'].includes(invoice.state))) {
      items.push(
        <MenuItem key={invoice.overdue ? 'unmarkOverdue' : 'markOverdue'} onSelect={toggleOverdue}>
          <i className="fas fa-fw fa-clock" /> {cms.text(invoice.overdue ? 'unmarkOverdue' : 'markOverdue')}
        </MenuItem>,
      );
    }

    if (!invoice.deleted) {
      items.push(
        <MenuItem key="share" onSelect={shareInvoice}>
          <i className="fas fa-fw fa-share" /> {cms.text('share')}
        </MenuItem>,
        <MenuItem key="download" href={`${apiUrl}/v1/admin/invoices/${invoice.sid.toUpperCase()}.pdf`} target="_blank">
          <i className="fas fa-fw fa-file-pdf" /> {cms.text('download')}
        </MenuItem>,
      );
    }

    if (hasRole('manager') && invoice.state === 'draft' && !invoice.deleted) {
      items.push(
        <MenuItem key="delete" onSelect={deleteInvoice}>
          <i className="fas fa-fw fa-trash" /> {cms.text('delete')}
        </MenuItem>,
      );
    }

    items.push(
      <tasksPlugin.AddTaskMenuItem key="addTask" />,
    )

    return items;
  }


  const menuItems = getMenuItems();
  if (!menuItems.length) return null;

  return (
    <cms.Object keys={wurdIds.join(',')}>
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="itemMenu"
        bsStyle="link"
        pullRight
        noCaret
      >
        {menuItems}
      </DropdownButton>
    </cms.Object>
  );
}
