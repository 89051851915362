import { getLangText } from 'helpers/ui';
import wurd from 'wurd-react';
import { Field, DatePicker } from '../../components/form2';
import Detail from './detail';


const detailStyles = {
  flex: 1,
  display: 'inline-flex',
  alignItems: 'center',
  maxWidth: 'min(100%, 500px)',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const cms = wurd.block('common.plugins.customFields');

function FilePicker({ name, value, onChange, required, ...props }) {
  function handleChange(evt) {
    const file = evt.target.files[0];

    const blobUrl = URL.createObjectURL(file);
    return onChange({
      target: {
        name,
        value: { url: blobUrl, name: file.name, file }
      }
    });
  }
  function handleDelete() {
    if (!value.url.startsWith('blob:') && !window.confirm(cms.text('confirmDeleteFile'))) return;

    return onChange({
      target: {
        name,
        value: ''
      }
    });
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={detailStyles}>
        <Detail value={value}  {...props} />
        {value?.url && <cms.Object type="button" keys="confirmDeleteFile,docSizeTooLarge" onClick={handleDelete} className="btn btn-link btn-flat mb-0"><i className="fa fa-times" /></cms.Object>}
      </span>

      <label className="btn btn-link btn-flat mb-0">
        <span><i className="fa fa-plus" /></span>
        <input
          type="file"
          key={value?.url}
          name={name}
          required={value?.url ? null : required}
          accept={null} // accept any
          onChange={handleChange}
          style={{ opacity: 0, position: 'absolute', left: 0, top: 0, width: 30 }}
        />
      </label>
    </div>
  )
}


const typeMap = {
  integer: 'number',
  boolean: 'checkbox',
  string: 'text',
  'date-time': 'datetime-local',
};

export const extrapolateValue = (helpText, value) => helpText?.replace(/{{value(?:(.)(\d))?}}/g, (__, splitChar, splitPos) => splitChar ? value?.split(splitChar)[splitPos] : value);

/**
 * 
 * @param {*} props
 * @param {string} props.code
 * @param {'string'|'number'|'integer'|'date'} props.type
 * @param {object} props.title
 * @param {array} props.enum
 */
export default function CustomField({ code, type, title, enum: _enum, ownerAcl, required, secret, help, ...props }) {
  return <Field
    label={getLangText(title)}
    type={secret && type !== 'file' ? 'secret' : typeMap[type] || type}
    help={secret ? getLangText(help) : extrapolateValue(getLangText(help), props.value)}
    required={!ownerAcl && required}
    {...props}
  >
    {type === 'date'
      ? (
        <DatePicker minDate={null} />
      )
      : type === 'file'
        ? (
          <FilePicker />
        )
        : _enum
          ? (
            <select>
              {!props.multiple && <option value=""></option>}
              {_enum.map(v => <option key={v} value={v}>{v}</option>)}
            </select>
          ) : null
    }
  </Field>
}
